import {countDown} from 'countdown';
(async () => {
  try {
    countDown();
  }
  catch (err) {
    console.log('Package Loading error: ' + err);
  }
})();

